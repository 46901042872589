import _taggedTemplateLiteral from "/builds/u5yGSXwH/11/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import gql from 'graphql-tag';
/*
 * IMPORTANT! Please do not edit this file without first making absolutely
 * sure that the fields you're adding do not hit SAP. If your fields are
 * in the cart resolver and have a full cart call it is very likely it will hit SAP.
 * Please run any changes to this file by your technical lead before merging.
 **/

export var ALL_CARTS = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query AllCarts {\n    getAllCarts {\n      ...QuickCart\n    }\n  }\n\n  fragment QuickCart on Cart {\n    id\n    cartIdAlias\n    count\n    cartType\n    items {\n      ...QuickCartItem\n    }\n    customerServiceInstructions\n    packingSlipNote\n    partners {\n      ...QuickCartPartners\n    }\n    salesOrderSource\n    contractNumber\n    selectedPaymentMethod\n    displaySaveCartButton\n    isEmproveProductExists\n  }\n\n  fragment QuickCartPartners on CartPartners {\n    SalesOrg\n    billTo\n    contact\n    payer\n    shipTo\n    soldTo\n  }\n\n  fragment QuickCartItem on CartItem {\n    id\n    quantity\n    brand\n    material {\n      id\n      description\n      displaySellerName\n      erpType\n      brand {\n        key\n        name\n        color\n        logo {\n          mediumUrl\n        }\n      }\n      productKey\n      product\n      image {\n        smallUrl\n        mediumUrl\n        largeUrl\n      }\n      name\n      number\n    }\n    quoteItemNumber\n    quoteNumber\n    promoCode\n    productNote\n    yourReference\n    type\n    displayEditButton\n    redirectLinkForEdit\n    configurationId\n    configuration {\n      name\n      value\n      sapCharc\n    }\n    productType\n    emproveDossierInfo {\n      dossierId\n      dossierSubType\n      dossierSubLength\n      dossierMaterialNumber\n    }\n    isSingleLotSelected\n    deviceSerialNumber\n    requestedDeliveryDate\n  }\n"])));